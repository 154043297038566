<script>
export default {
  props: {
    historicoPadrao: { type: Object },
  },
  data() {
    return {
      
    }
  },
}
</script>

<template>
<div class="historicoPadrao">
  <p>ID: {{ historicoPadrao.id }}</p>
  <p>Nome: {{ historicoPadrao.descricao }}</p>
  <p>Usuário: {{ historicoPadrao.user.name }}</p>
  <p>Ativo: <b-badge data-name="status" class="field-status" v-bind:variant="(historicoPadrao.status) ? 'success' : 'danger'">{{ (historicoPadrao.status) ? 'Sim' : 'Não' }}</b-badge></p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ historicoPadrao.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ historicoPadrao.updated_at }}</b-badge></p>
</div>
</template>